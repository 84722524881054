@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PoetsenOne";
  src: url("./components/assets/font/PoetsenOne-Regular.ttf");
}

@font-face {
  font-family: "arial";
  src: url("./components/assets/arial/ARIALBD.TTF");
}

.font-arial {
  font-family: "arial";
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F95292;
  font-family: "PoetsenOne";
}

.logo {
  text-shadow: 2px 2px 0px #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

.nav-shadow {
  border: 2px solid #1A1A1A;
  background: rgba(255, 255, 255, 0.00);
  box-shadow: 5px 5px 0px 2px rgba(255, 255, 255, 0.20) inset,
    5px 5px 0px 0px rgba(0, 0, 0, 0.08);
}

.main-heading {
  color: #FFF;
  text-shadow: 4px 4px 0px #000;
  -webkit-text-stroke-width: 4.0789475440979px;
  -webkit-text-stroke-color: #000;
}

.btn {
  border-radius: 7px;
  border: 2px solid #000;
  background: #FF93BC;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.10), 5px 5px 0px 2px rgba(255, 255, 255, 0.28) inset;
}

.btn-2 {
  border-radius: 7px;
  border: 2px solid #000;
  background: #F95292;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.10), 5px 5px 0px 2px rgba(255, 255, 255, 0.28) inset;
}

.media-box {
  border-radius: 20px;
  border: 2px solid #1A1A1A;
  background: #FF93BC;
  box-shadow: 5px 5px 0px 2px rgba(255, 255, 255, 0.20) inset, 5px 5px 0px 0px rgba(0, 0, 0, 0.08);
}

.address-bg {
  background-image: url("./components/assets/png/address-bg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.about-box {
  border-radius: 20px;
  border: 2px solid #1A1A1A;
  background: #FF93BC;
  box-shadow: 5px 5px 0px 2px rgba(255, 255, 255, 0.20) inset, 5px 5px 0px 0px rgba(0, 0, 0, 0.08);
}

.by-bg {
  border-radius: 20px 20px 0px 0px;
  border: 4px solid rgba(26, 26, 26, 0.70);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.70) -0.63%, rgba(0, 0, 0, 0.07) 24.76%, rgba(0, 0, 0, 0.00) 45.2%, rgba(0, 0, 0, 0.42) 62.45%, rgba(0, 0, 0, 0.70) 100%), url("./components/assets/png/buy-bg.png") lightgray 50% / cover no-repeat;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.08), 5px 5px 0px 2px rgba(255, 255, 255, 0.20) inset;
}

.buy-2 {
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.10),
    5px 5px 0px 2px rgba(255, 255, 255, 0.28);
}

.sub-heading {
  color: #000;
  text-shadow: 0px 1px 0px #000;
}

.tokenomics-bg {
  border-radius: 20px 20px 0px 0px;
  border: 4px solid rgba(26, 26, 26, 0.70);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.70) -0.63%, rgba(0, 0, 0, 0.07) 24.76%, rgba(0, 0, 0, 0.00) 45.2%, rgba(0, 0, 0, 0.42) 62.45%, rgba(0, 0, 0, 0.70) 100%), url("./components/assets/png/tokenomics.png") lightgray 50% / cover no-repeat;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.08), 5px 5px 0px 2px rgba(255, 255, 255, 0.20) inset;
}

.card-box {
  border-radius: 7px;
  border: 2px solid #000;
  background: #F95292;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.10), 5px 5px 0px 2px rgba(255, 255, 255, 0.28) inset;
}

@media (max-width:768px) {
  .main-heading {
    text-shadow: 1.5px 1.5px 0px #000;
    -webkit-text-stroke-width: 1.2px;
  }

  .address-bg-2 {
    background-image: none;
  }

}

@media (min-width:768px) and (max-width:1280px) {
  .main-heading {
    text-shadow: 2.5px 2.5px 0px #000;
    -webkit-text-stroke-width: 2.2px;
  }

}



@media (max-width: 1920px) {

  iframe .trade-widget {
    width: 700px !important;
    border: none;
    margin-left: auto;
    background-color: gray;
  }
}