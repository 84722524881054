.hero-bg {
  background-image: url("./components/assets/png/hero-bg.webp");
  border: 4px solid rgba(26, 26, 26, 0.30);
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.08),
    5px 5px 0px 2px rgba(255, 255, 255, 0.20) inset;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.coin-swap-bg {
  border-radius: 13.969px 13.969px 0px 0px;
  border-top: 3px solid #000;
  border-right: 3px solid #000;
  border-left: 3px solid #000;
  background: #FF93BC;
}

.box-shadow-swap {
  border-radius: 10px;

  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@keyframes move {

  0%,
  100% {
    transform: translateX(-16px);
    /* animation-timing-function: cubic-bezier(0.8, 0, 1, 1); */
  }

  50% {
    transform: translateX(16px);
    /* animation-timing-function: cubic-bezier(0, 0, 0.2, 1); */
  }
}

.animate-bounce {
  animation: move 3s infinite;
}